<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/service/list"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Podgląd serw.</h2>
      <button
        @click="doReady"
        v-if="item.status == 'open'"
        class="btn btn-lg btn-success me-2"
      >
        Napraw
      </button>
      <button
        @click="doDone"
        v-if="item.status == 'ready'"
        class="btn btn-lg btn-primary me-2"
      >
        Wydaj
      </button>

      <button
        v-if="printer_id"
        :disabled="printDisabled"
        class="btn btn-lg btn-secondary me-2"
        @click.prevent="print"
      >
        <i class="bi bi-printer"></i>
      </button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="bg-body-tertiary border rounded-3 p-3 d-block mb-3 text-black w-100">
        <div class="row fw-bold mb-2 mb-sm-1">
          <div class="col-6 col-sm-4">Nr zlecenia:</div>
          <div class="col-6 col-sm-8">
            {{ item?.order.service_number }}/{{ item?.order.service_year }}
          </div>
        </div>

        <div class="row mb-2 mb-sm-1">
          <div class="col-12 col-sm-4">Klient:</div>
          <div class="col-12 col-sm-8">
            {{ item.customer?.nazwa }}
          </div>
        </div>

        <div class="row mb-2 mb-sm-1">
          <div class="col-6 col-sm-4">Data dodania:</div>
          <div class="col-6 col-sm-8">{{ item.created_at.date.substr(0, 16) }}</div>
        </div>
        <div class="row mb-2 mb-sm-1">
          <div class="col-6 col-sm-4">Magazyn przyjęcia:</div>
          <div class="col-6 col-sm-8">{{ item.location?.location_name }}</div>
        </div>
        <div class="row mb-2 mb-sm-1">
          <div class="col-6 col-sm-4">Przyjął:</div>
          <div class="col-6 col-sm-8">{{ item?.user.name }}</div>
        </div>
        <div
          class="row mb-2 mb-sm-1"
          v-if="item?.notification_phone"
        >
          <div class="col-6 col-sm-4">Tel. kontaktowy:</div>
          <div class="col-6 col-sm-8">{{ item?.notification_phone }}</div>
        </div>

        <div class="row mb-2 mb-sm-1">
          <div class="col-6 col-sm-4">Status</div>
          <div class="col-6 col-sm-8">
            <ServiceStatusBadge :status="item.status" />
          </div>
        </div>
      </div>

      <a
        class="card mb-3 text-black w-100"
        href="#"
      >
        <div class="card-body">
          <h5 class="card-title">
            <router-link
              :to="'/cylinder/qrcode/' + item.cylinder?.cylinder_qr_code"
              class="badge bg-dark fs-6"
              >#{{ item.cylinder?.cylinder_qr_code }}</router-link
            >
          </h5>

          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Nr seryjny:</div>
            <div class="col-8">{{ item.cylinder?.cylinder_serial_number }}</div>
          </div>
          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Rok produkcji:</div>
            <div class="col-8">{{ item.cylinder?.cylinder_production_date ?? "-" }}</div>
          </div>
          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Typ gazu:</div>
            <div class="col-8">{{ item.cylinder?.cylinder_working_factor ?? "-" }}</div>
          </div>
          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Pojemność butli:</div>
            <div class="col-8">{{ item.cylinder?.cylinder_capacity ?? "-" }} l</div>
          </div>
          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Waga butli:</div>
            <div class="col-8">{{ item.cylinder?.cylinder_weight }} kg</div>
          </div>
          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Usługi</div>
            <div class="col-8">
              <ul>
                <li
                  v-for="(type, key) in item.service_type"
                  :key="key"
                >
                  {{ $store.state.service.dictionary.service_types[type] }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row mb-2 mb-sm-1 card-text small text-muted">
            <div class="col-4 col-sm-4">Uwagi:</div>
            <div class="col-8">
              {{ item.description ? item.description : "---" }}
            </div>
          </div>
        </div>
      </a>

      <!-- PRZYCISKI AKCJI -->
      <div class="d-grid gap-2 w-100">
        <a
          class="btn btn-outline-secondary"
          type="button"
          target="_blank"
          :href="'https://apps.alterct.com/blueapp/service/order/' + item.parent_id + '/pdf'"
        >
          <i class="bi bi-printer me-2"></i> Potwierdzenie przyjęcia
        </a>
        <a
          :href="'https://apps.alterct.com/blueapp/service/order/' + item.parent_id + '/pdfpdf'"
          class="btn btn-outline-secondary"
          type="button"
          target="_blank"
        >
          <i class="bi bi-printer me-2"></i> Protokół wydania
        </a>
      </div>

      <!-- <h5>Numer serwisu: {{ item?.order.service_number }}/{{ item?.order.service_year }}</h5>
      <div>Numer seryjny: {{ item.cylinder?.cylinder_serial_number }}</div>
      <div>Magazyn przyjęcia: {{ item.location?.location_name }}</div>
      <hr />
      <div>Klient: {{ item.customer?.nazwa }}</div>
      <div>Data: {{ item.created_at.date.substr(0, 16) }}</div>
      <div>Status: <ServiceStatusBadge :status="item.status" /></div>
      <div>
        <label>Usługi:</label>
        <ul>
          <li
            v-for="(type, key) in item.service_type"
            :key="key"
          >
            {{ $store.state.service.dictionary.service_types[type] }}
          </li>
        </ul>
      </div>
      <div
        class="my-4"
        v-if="item.items.length > 1"
      >
        <h4>Inne butle w tym procesie:</h4>

        <div
          v-for="(it, key) in item.items"
          :key="key"
        >
          <div
            class="my-1 fs-5"
            v-if="it.service_requests_id != item.service_requests_id"
          >
            <router-link :to="'/service/show/' + it.service_requests_id"
              >#{{ it.cylinder.cylinder_qr_code }}</router-link
            >
          </div>
        </div>
      </div>
      <a
        class="btn btn-outline-secondary my-4"
        target="_blank"
        :href="'https://apps.alterct.com/blueapp/service/order/' + item.parent_id + '/pdf'"
        >Drukuj potwierdzenie</a
      > -->
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";
import ServiceStatusBadge from "@/components/service/ServiceStatusBadge.vue";

export default {
  name: "SimpleaddIndex",
  components: { ServiceStatusBadge },
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      item: {},
      date: "",
      loading: false,
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
    printer_id: function () {
      return localStorage.getItem("printer_id") ?? null;
    },
  },
  methods: {
    print: function () {
      let vm = this;
      this.$swal
        .fire({
          title: "Drukowanie etykiety",
          text: "Domyślnie drukowana jest 1 etykieta",
          input: "select",
          inputOptions: {
            1: 1,
            2: 2,
            3: 3,
          },
          inputPlaceholder: "Ilość etykiet (1)",
          showCancelButton: true,
          cancelButtonText: "Anuluj",
        })
        .then((r) => {
          console.log(r);
          if (r.isConfirmed) {
            for (let i = 0; i < (r.value ? parseInt(r.value) : 1); i++) {
              myApi.printServiceLabels(vm.item.service_requests_id, vm.printer_id).then(
                () => {
                  console.log("print_success");
                },
                () => {
                  vm.$swal({
                    icons: "error",
                    title: "Błąd drukarki",
                    text: "Nie można było dodać zadania do kolejki druku",
                  });
                }
              );
            }
            vm.printDisabled = true;
            setTimeout(function () {
              vm.printDisabled = false;
            }, 10000);
            vm.$swal({
              icons: "success",
              title: "Wysłano do druku",
              text: "Drukarka w ciągu kilku sekund wydrukuje twoją etykietę",
            });
          }
        });
    },
    doDone: function () {
      this.$swal({
        icon: "question",
        title: "Czy napewno?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Anuluj",
      }).then((r) => {
        if (r.isConfirmed) {
          myApi
            .putServiceDone(this.item.service_requests_id, {
              location_id: this.$store.state.location_id,
            })
            .then(() => {
              this.$swal({ icon: "success", title: "Oznaczono jako wykonane" });
              this.load();
            });
        }
      });
    },
    doReady() {
      this.$swal({
        icon: "question",
        title: "Czy napewno?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Anuluj",
      }).then((r) => {
        if (r.isConfirmed) {
          myApi
            .putServiceReady(this.item.service_requests_id, {
              location_id: this.$store.state.location_id,
            })
            .then(() => {
              this.$swal({ icon: "success", title: "Oznaczono jako naprawione" });
              this.load();
            });
        }
      });
    },
    load() {
      myApi.getService(this.$route.params.id).then((response) => {
        this.item = response.data;
      });
    },
  },
  created() {},
  mounted() {
    this.load();
    this.$store.dispatch("service/loadDictionary");
  },
};
</script>
